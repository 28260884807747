import { widgetDatasets } from '../../../../enums/datasets';

export const equipmentConfigurationSettings = {
  'id': 'equipmentConfigurationSettingsId',
  'kql': `
    EquipmentLiveDataset
    | join kind=leftouter
    (
        DiagnosticResultsDataset
        | where ResultClassID == 11
        | where ResultTypeID != 370
        | summarize ResultCount=count() by EquipmentID
        | project-rename EquipMissingInfoResultFound=ResultCount
    )
    on EquipmentID
    | join kind=leftouter
    (
        DiagnosticResultsDataset
        | where ResultClassID == 6
        | summarize ResultCount=count() by EquipmentID
        | project-rename ConfigIssueResultFound=ResultCount
    )
    on EquipmentID
    | join kind=leftouter
    (
        PointsDataset
        | summarize PointCount=count(PointID) by EquipmentID
    )
    on EquipmentID
    | extend EquipMissingInfo=(EquipMissingInfoResultFound > 0)
    | extend ConfigurationIssues=(ConfigIssueResultFound > 0)
    | project OrganizationID, OrganizationName, BuildingID, BuildingName, EquipmentID, EquipmentName, EquipmentTypeID, EquipmentTypeName, EquipmentClassID, EquipmentClassName, EquipMissingInfo,  EquipmentTags=parse_csv(EquipmentTags), ConfigurationIssues, ConfigurationStatusID, ConfigurationStatusName, ConfigurationNotes, PointCount, IsEquipmentActive, IsEquipmentVisible, EquipmentNotes, CMMSReferenceID, CMMSLocationID, CMMSSiteID, CMMSLink
  `,
  'filterOverrides': 'DiagnosticStartDate=$d1&DiagnosticEndDate=$d0&DiagnosticAnalysisInterval=Daily',
  'datasetIDs': [widgetDatasets.DiagnosticResultsDataset, widgetDatasets.Equipment_LIVE, widgetDatasets.Points],
};
