import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import {useSelector} from 'react-redux';
import cn from 'classnames';

function LabelsFilter(props) {
  const { display, onApplyFilter, value: filterId, filterValue } = props;
  const taskLabelsList = useSelector((state) => state.taskLabels);
  const equipmentLabelsList = useSelector((state) => state.equipmentLookup.equipmentLabels);
  const labelsList = filterId === 'taskLabel' ? taskLabelsList : equipmentLabelsList;

  const [newValue, setValue] = useState(filterValue);

  useEffect(() => {
    updateValueFromProps();
  }, [filterValue]);

  return (
    <div className="columns is-gapless is-vcentered">
      <p className='column'>{display}</p>
      <div className='filter-interval field has-addons'>
        <MultiSelect
          data={labelsList}
          value={newValue}
          autoClose={false}
          allowCustom={true}
          name={'taskLabelsFilter'}
          onChange={handleChange}
          className={cn('dropdownMultiSelect border-0 filter', { hasValue: newValue.length > 0 })}
          popupSettings={{ className: 'labels-dropdown-popup' }}
        />
      </div>
    </div>
  );

  function updateDashboardFilter(changedValue = newValue) {
    onApplyFilter(filterId, changedValue);
  }

  function updateValueFromProps() {
    setValue(filterValue);
  }

  function handleChange(event) {
    const values = event.target.value;
    const lastItem = values[values.length - 1];
    if (lastItem) {
      values.pop();
      const sameItem = values.find((value) => value.toLowerCase() === lastItem.toLowerCase());
      if (sameItem === undefined) {
        values.push(lastItem);
      }
    }
    setValue(values);
    updateDashboardFilter(values);
  }
}

LabelsFilter.defaultProps = {
  filterValue: [],
};
LabelsFilter.propTypes = {
  display: PropTypes.node,
  value: PropTypes.string.isRequired,
  filterValue: PropTypes.arrayOf(PropTypes.string),
  onApplyFilter: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
};

export default LabelsFilter;
